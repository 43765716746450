import { graphql, Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React, { useEffect } from 'react';

import { BlogPostPreview, Layout, SEO } from '../components';
import NavigateToSearch from '../components/search/navigate-to-search';
import './style.scss';

const Home = ({ data: { blogPosts, headerImage } }) => {
  const posts = blogPosts.edges;

  useEffect(() => {
    requestAnimationFrame(() => window.scrollTo(0, 0));
  }, []);

  return (
    <Layout bodyClass="page-home standard-layout">
      <SEO
        title="YOU are safe online"
        description="We'll make sure of it"
        url=""
      />

      <BackgroundImage
        Tag="section"
        className="header-section bg-white min-h-screen flex flex-col"
        fluid={headerImage.childImageSharp.fluid}
      >
        <div className="container flex flex-col justify-center flex-1">
          <h1 className="flex items-end bg-white mt-4 p-4 pb-0">
            YOU are safe online
          </h1>
          <h2 className="flex items-end bg-white p-4 pt-0">
            We'll make sure of it.
          </h2>
          <p className="flex items-end bg-white p-4">
            What can we help you with? 👇
          </p>

          <div className="flex items-end mt-8 w-full">
            <NavigateToSearch className="w-full" autoFocus />
          </div>
        </div>
      </BackgroundImage>

      <section className="body-section mt-16">
        <div className="container justify-around">
          <h2 className="mb-4">Recent blog posts</h2>

          <div className="flex flex-row flex-wrap justify-between">
            {posts &&
              posts.map((post) => (
                <BlogPostPreview
                  key={post.node.path}
                  node={post.node}
                  displayAuthor
                />
              ))}
          </div>

          <div className="my-10">
            <Link
              className="block btn btn-secondary mt-2 text-center max-w-xs mx-auto"
              to="/blog"
            >
              Read more on our blog
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    blogPosts: allBlogPost(
      sort: { fields: [modifiedDate], order: DESC }
      limit: 6
    ) {
      edges {
        node {
          excerpt
          timeToRead
          title
          path
          publishedDate
          author {
            frontmatter {
              name
            }
          }
          image {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    headerImage: file(
      relativePath: { eq: "home/s-b-vonlanthen-BrbogxYe7FE-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Home;
